import { getCookie } from "./utils";

export default function promptEditor({ promptId, activePromptHistoryId, selectedHistoryId, isPublishedStreamSelected, useDefaultModel, selectAllUserContexts }) {
    return {
        previewing: false,
        editing: {
            pane: "settings",
        },
        variables: {
            pane: "global",
        },
        editors: [],
        saving: false,
        useDefaultModel: useDefaultModel,
        selectAllUserContexts: selectAllUserContexts,
        activePromptHistoryId: activePromptHistoryId,
        selectedHistoryId: selectedHistoryId,
        isPublishedStreamSelected: isPublishedStreamSelected,

        init() {
            window.addEventListener("valence:remove-editor", (event) => {
                this.removeEditor(event.detail);
            });

            window.addEventListener("valence:register-editor", (event) => {
                let editor = event.detail;
                this.registerEditor(editor);
            });

            window.addEventListener("beforeunload", (e) => {
                if (this.hasChanges()) {
                    e.returnValue = "You have unsaved changes. Are you sure you want to leave?";
                }
            });
            const selectAllCheckbox = document.getElementById("select_all_context");
            selectAllCheckbox.addEventListener("change", (e) => {
                const checkboxes = document.querySelectorAll('[id^="ignored-"]');
                checkboxes.forEach((checkbox) => {
                    checkbox.checked = e.target.checked;
                });
            });

            this.$nextTick(() => {
                // HTMX must reprocess DOM that is rendered using <template x-if="">
                htmx.process(this.$root.querySelector("#edit-mode-pane"));
                this.$watch("editing.pane", () => {
                    htmx.process(this.$root.querySelector("#edit-mode-pane"));
                });
            });
        },

        hasChanges() {
            return this.editors.some((editor) => editor.dirty);
        },

        registerEditor(editor) {
            this.editors.push(editor);
        },

        removeEditor({ id, onRemoved }) {
            this.editors = this.editors.filter((editor) => editor.id !== id);
            onRemoved();
            this.savePrompts();
        },

        async savePrompts() {
            this.saving = true;
            let editorList = Array.from(this.$refs.promptsContainer);
            let stream = await Promise.all(
                this.editors
                    .sort((a, b) => {
                        return editorList.indexOf(a.$root) - editorList.indexOf(b.$root);
                    })
                    .map(async (item) => await item.getPrompt()),
            );

            const payload = { messages: stream };
            const chatStream = JSON.stringify(payload);

            htmx.ajax("POST", `/prompts/edit/${promptId}/${selectedHistoryId}?prompts=true`, {
                values: {
                    chat_stream: chatStream,
                },
                headers: {
                    "X-CSRFToken": getCookie("csrftoken"),
                },
                swap: "innerHTML",
                target: "#prompt-save-response",
            })
                .then(() => {
                    this.editors.forEach((editor) => editor.resetPromptState());
                })
                .finally(() => {
                    this.saving = false;
                });
        },
    };
}
