import { getCookie } from "./utils";

export default function updateSkippableOnboardingQuestions(questions) {
    return {
        async saveSelectedQuestions(questions) {
            try {
                const response = await fetch('/organization/onboarding_questions', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'X-CSRFToken': getCookie('csrftoken')
                    },
                    body: JSON.stringify({ questions: questions })
                });
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                alert('Questions saved successfully!');
            } catch (error) {
                alert(`An error occurred while saving the questions: ${error}`);
            }
        }
    };
}
